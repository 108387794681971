import React from 'react';

import Homepage from '../../templates/Homepage';

const Home = ({ location }) => {
  return (
    <Homepage
      location={location}
      seo={{ meta: [{ name: 'robots', content: 'noindex' }] }}
    />
  );
};

Home.displayName = 'Home';

export default Home;
